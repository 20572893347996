import React, { useState } from "react";
import { graphql } from "gatsby";
import Styled from "styled-components";
import Layout from "@components/Layout";
import { Section } from "@components/StyledComponents";
import ContactMe from "@components/ContactMe";
import ExternalLink from "@components/ExternalLink";

const Container = Styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    grid-template-areas:
    "left right";
    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
        "left"
        "right";
    }
`;
const Left = Styled.div`
    grid-area: left;
    place-self: start;
    justify-self: center;
    font-family: ${({ theme }) => theme.fontSecondary};
    h3, h4, h5 {
        text-align: center;
    }
    h4 {
        color: ${({ theme }) => theme.grey};
    }
    h5 {
        a {
            color: ${({ theme }) => theme.black};
            text-decoration: none;
            &:hover {
                color: ${({ theme }) => theme.action};
            }
        }
    }
`;
const Right = Styled.div`
    grid-area: right;
    place-self: start;
    justify-self: center;
    font-family: ${({ theme }) => theme.fontSecondary};
    h3 {
        text-align: center;
    }
    table {
        border-spacing: 0px;
        text-align: left;
        display: inline-table;
    }
`;
const Tr = Styled.tr`
    color: ${({ theme, isToday }) => (isToday ? theme.black : theme.grey)};
    vertical-align: middle;
`;
const ContactPage = ({ data }) => {
  const [today] = useState(new Date());
  const header = data.header.childImageSharp.fluid;
  return (
    <Layout
      seoTitle="Contact"
      seoDescription="Contact Cassandra Lee & Co"
      fluid={header}
      alt="Contact Cassandra Lee & Co"
      headerTitle="Buy.Sell.Invest"
      headerSubTitle="Your home is my commitment"
      map={true}
    >
      <Section>
        <h2>Cassandra Lee</h2>
        <hr />
        <ContactMe />
      </Section>
      <Section>
        <Container>
          <Left>
            <h3>Cassandra Lee</h3>
            <h4>3261 Chaparral Drive, Saint George, Utah 84790, United States</h4>
            <h5>
              <ExternalLink href="tel:4352726097">435-272-6097</ExternalLink>
            </h5>
          </Left>
          <Right>
            <h3>Hours</h3>
            <table>
              <tbody>
                <Tr isToday={today.getDay() === 0}>
                  <td>Mon</td>
                  <td>
                    <span>09:00AM – 07:30PM</span>
                  </td>
                </Tr>
                <Tr isToday={today.getDay() === 1}>
                  <td>Tue</td>
                  <td>
                    <span>09:00AM – 05:00PM</span>
                  </td>
                </Tr>
                <Tr isToday={today.getDay() === 2}>
                  <td>Wed</td>
                  <td>
                    <span>09:00AM – 07:30PM</span>
                  </td>
                </Tr>
                <Tr isToday={today.getDay() === 3}>
                  <td>Thu</td>
                  <td>
                    <span>09:00AM – 07:30PM</span>
                  </td>
                </Tr>
                <Tr isToday={today.getDay() === 4}>
                  <td>Fri</td>
                  <td>
                    <span>09:00AM – 07:30PM</span>
                  </td>
                </Tr>
                <Tr isToday={today.getDay() === 5}>
                  <td>Sat</td>
                  <td>
                    <span>By Appointment</span>
                  </td>
                </Tr>
                <Tr isToday={today.getDay() === 6}>
                  <td>Sun</td>
                  <td>
                    <span>By Appointment</span>
                  </td>
                </Tr>
              </tbody>
            </table>
          </Right>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ContactQuery {
    header: file(relativePath: { eq: "unsplash_3_1200x798.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ContactPage;
